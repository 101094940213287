
import { defineComponent } from 'vue'
import { getTopMovies } from '@/services/IMDBService'
import CatalogPlaceholder from '@/components/CatalogPlaceholder.vue'

export default defineComponent({
  name: 'TopMoviesCatalog',
  components: {
    CatalogPlaceholder
  },
  data () {
    return {
      topMovies: [],
      loading: true
    }
  },
  created () {
    this.getTopMovies()
  },
  methods: {
    getTopMovies () {
      getTopMovies().then(response => {
        this.topMovies = response.results
        this.loading = false
      })
    }
  }
})
