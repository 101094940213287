
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CatalogPlaceholder',
  data () {
    return {
      items: 6
    }
  }
})
