
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      trending: [],
      loading: true
    }
  },
  created () {
    this.getTrendingToday()
  },
  methods: {
    getTrendingToday () {
      this.loading = false
    }
  }
})
