
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavBar',
  data () {
    return {
      query: ''
    }
  },
  props: {
    siteName: String
  },
  methods: {
    submitClick () {
      window.location.href = '/search/' + encodeURIComponent(this.query) + '/1'
    }
  }
})
