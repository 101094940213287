import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'

const dmcaMovies = [
  577922,
  438631,
  787699
]

const dmcaShows = [
  222425
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Watch Movies & TV Shows Online Free Online',
      metaTags: [
        {
          name: 'description',
          content: 'Watch Movies & TV Shows Online Free with our stream.'
        },
        {
          property: 'og:description',
          content: 'Watch Movies & TV Shows Online Free with our stream.'
        }
      ]
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/errors/404.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import(/* webpackChunkName: "donate" */ '../views/DonateView.vue')
  },
  {
    path: '/search/:query/:page',
    name: 'search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "search" */ '../views/SearchView.vue')
  },
  {
    path: '/watch-movie/:movie',
    name: 'watch-movie',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "watch-movie" */ '../views/WatchMovieView.vue')
  },
  {
    path: '/watch-tv/:show',
    name: 'watch-show',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "watch-show" */ '../views/WatchShowView.vue')
  },
  {
    path: '/watch-person/:person',
    name: 'watch-person',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "watch-person" */ '../views/WatchPersonView.vue')
  },
  {
    path: '/watch-anime/:anime',
    name: 'watch-anime',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "watch-anime" */ '../views/WatchAnimeView.vue')
  },
  {
    path: '/movies',
    name: 'movies',
    component: () => import(/* webpackChunkName: "movies" */ '../views/BrowseMoviesView.vue')
  },
  {
    path: '/movies/:genre/:page',
    name: 'movies-genre',
    props: true,
    component: () => import(/* webpackChunkName: "movies-genre" */ '../views/BrowseMovieGenreView.vue')
  },
  {
    path: '/shows',
    name: 'shows',
    component: () => import(/* webpackChunkName: "shows" */ '../views/BrowseShowsView.vue')
  },
  {
    path: '/shows/:genre/:page',
    name: 'shows-genre',
    props: true,
    component: () => import(/* webpackChunkName: "shows-genre" */ '../views/BrowseShowGenreView.vue')
  },
  {
    path: '/discord',
    name: 'discord',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "discord" */ '../views/DiscordView.vue')
  },
  {
    path: '/halloween',
    name: 'halloween',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "halloween" */ '../views/HalloweenView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            top: 0,
            behavior: 'smooth'
          })
        }, 25)
      })
    }
  }
})

// Middleware
router.beforeEach((to, from, next) => {
  // Check if they are going to a movie or tv show
  if (to.name === 'watch-movie') {
    if (dmcaMovies.includes(Number(to.params.movie))) {
      next({ name: 'movies' })
    }
  } else if (to.name === 'watch-show') {
    if (dmcaShows.includes(Number(to.params.show))) {
      next({ name: 'shows' })
    }
  }
  next()
})

export default router
