
import { defineComponent } from 'vue'
import { getTrending } from '@/services/IMDBService'
import CatalogPlaceholder from '@/components/CatalogPlaceholder.vue'

export default defineComponent({
  name: 'TrendingCatalog',
  components: {
    CatalogPlaceholder
  },
  data () {
    return {
      trending: [],
      loading: true
    }
  },
  created () {
    this.getTrending()
  },
  methods: {
    getTrending () {
      getTrending().then(response => {
        this.trending = response.results
        this.loading = false
      })
    }
  }
})
