
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'FooterView',
  props: {
    siteName: String
  },
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
})
