
import { defineComponent } from 'vue'
import { getTopShows } from '@/services/IMDBService'
import CatalogPlaceholder from '@/components/CatalogPlaceholder.vue'

export default defineComponent({
  name: 'TopShows',
  components: {
    CatalogPlaceholder
  },
  data () {
    return {
      topShows: [],
      loading: true
    }
  },
  created () {
    this.getTopShows()
  },
  methods: {
    getTopShows () {
      getTopShows().then(response => {
        this.topShows = response.results
        this.loading = false
      })
    }
  }
})
